import React, { Component } from 'react'
import axios from 'axios'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Banner from './banner';

const now = new Date();
function checkZero(num) {
    console.log((num + "").length)
    return (num + "").length === 1 ? "0" + num : num;
}
export default class Signup extends Component {
    state={error: '', loader: false,  firstname: '', firstnameError: '', salutationId: '', salutationError: '', surname: '', surnameError: '', type: '', typeError: '', gender: '', genderError: '', dateOfBirth: now.getFullYear() + '-' + checkZero(now.getMonth() + 1) + '-' + checkZero(now.getDate()), dateOfBirthError: '', salutations: [], genders: [],
    mobile: '', mobileError: '', pincode: '', pincodeError: '', pincode1: '', pincode1Error: '', email: '', emailError: '', showSubmit: false}
    componentDidMount() {
        axios.get(localStorage.getItem('host') + '/login/getuserdropdowns').then(res => {
          this.setState({ salutations: res.data.salutations, genders: res.data.genders});
        }, err => {
          console.log(".....")
        })
    
      }
    doValidate() {
        setTimeout(() => {
            this.validate();
        }, 1000);
    }
    
    validate() {
           
        var error = false;
        if (this.state.firstname === null) {
            this.setState({ firstnameError: "Firstname cannot be empty" });
            error = true
        }
        else if (this.state.firstname === "") {
            this.setState({ firstnameError: "Firstname cannot be empty" });
            error = true
        }
        else if(!(/^(?!\s*$)[-a-zA-Z ]*$/.test(this.state.firstname)))
            {
            this.setState({ firstnameError: "Firstname should contain characters only and no spaces"})
            error = true
            }
        else {
            this.setState({ firstnameError: "" })
        }

        if (this.state.surname === null) {
            this.setState({ surnameError: "Surname cannot be empty" })
            error = true
        }
        else if (this.state.surname === "") {
            this.setState({ surnameError: "Surname cannot be empty" })
            error = true
        }
        else if(!(/^(?!\s*$)[-a-zA-Z ]*$/.test(this.state.surname)))
            {
            this.setState({ surnameError: "Surname should contain characters only and no spaces"})
            error = true
            }
        else {
            this.setState({ surnameError: "" })
        }

        if (this.state.salutationId === 0) {
            this.setState({ salutationError: "Salutation cannot be empty" })
            error = true
        }
        else if (this.state.salutationId === "") {
            this.setState({ salutationError: "Salutation cannot be empty" })
            error = true
        }
        else {
            this.setState({ salutationError: "" })
        }
        if (this.state.gender === null) {
            this.setState({ genderError: "Gender cannot be empty" })
            error = true
        }
        else if (this.state.gender === "") {
            this.setState({ genderError: "Gender cannot be empty" })
            error = true
        }
        else {
            this.setState({ genderError: "" })
        }
        if (this.state.dateOfBirth === null) {
            this.setState({ dateOfBirthError: "Date Of Birth cannot be empty" })
            error = true
        }
        else if (this.state.dateOfBirth === "") {
            this.setState({ dateOfBirthError: "Date Of Birth cannot be empty" })
            error = true
        }
        else if(!(/^(\d{4})-(\d{2})-(\d{2})$/.test(this.state.dateOfBirth)))
            {
            this.setState({ dateOfBirthError: "Date Of Birth wrong format"})
            error = true
            }
        else {
            this.setState({ dateOfBirthError: "" })
        }
        if (this.state.mobile === null) {
            this.setState({ mobileError: "Mobile Number cannot be empty" })
            error = true
        }
        else if (this.state.mobile === "") {
            this.setState({ mobileError: "Mobile Number cannot be empty" })
            error = true
        }
        else if(!(/^[7]\d{8}$/.test(this.state.mobile)))
            {
            this.setState({ mobileError: "Please numbers only should not start with zero like 772747211"})
            error = true
            }
        else {
            this.setState({ mobileError: "" })
        }
    
        if (this.state.type === null) {
            this.setState({ typeError: "OTP via cannot be empty" })
            error = true
        }
        else if (this.state.type === "") {
            this.setState({ typeError: "OTP via cannot be empty" })
            error = true
        }
        else {
            this.setState({ typeError: "" })
        }
        if (this.state.pincode === null) {
            this.setState({ pincodeError: "Pincode cannot be empty" })
            error = true
        }
        else if (this.state.pincode === "") {
            this.setState({ pincodeError: "Pincode cannot be empty" })
            error = true
        }
        else if(!(/^(\d{4})$/.test(this.state.pincode)))
            {
            this.setState({ pincodeError: "Pincode should be 4 digits only"})
            error = true
            }
        else {
            this.setState({ pincodeError: "" })
        }

        if (this.state.pincode1 === null) {
            this.setState({ pincode1Error: "Pincode cannot be empty" })
            error = true
        }
        else if (this.state.pincode1 === "") {
            this.setState({ pincode1Error: "Pincode cannot be empty" })
            error = true
        }
        else if(!(/^(\d{4})$/.test(this.state.pincode1)))
            {
            this.setState({ pincode1Error: "Pincode should be 4 digits only"})
            error = true
            }
        else if(this.state.pincode1 !== this.state.pincode){
            this.setState({ pincode1Error: "Pincodes do not match" , pincodeError: "Pincodes do not match" })
        }
        else {
            this.setState({ pincode1Error: "" })
        }
        if(error === true)
               {
                this.setState({ error: "One or more of the mandatory field(s) is not filled in", showSubmit: false}) 
               }
            else
               {
                this.setState({error: "", showSubmit: true}) 
               }   
    }
    signup() {
        this.setLoader(true)
        var data = {firstname: this.state.firstname, salutationId: this.state.salutationId,  
            surname: this.state.surname, 
            gender: this.state.gender, dateOfBirth: this.state.dateOfBirth,
            email: this.state.email, type: this.state.type,
            mobile: this.state.mobile, pincode: this.state.pincode,
            ipAddress: this.state.ipAddress,
            country: this.state.country,
            isp: this.state.isp}
        axios.post(localStorage.getItem('host') + '/login/signup', data).then(
            res => {
                this.setLoader(false)
                if (res.data.includes("Error")) {
                    this.setState({ error: res.data });
                  }
                  else {                   
                    localStorage.setItem('mobile', this.state.mobile)
                    localStorage.setItem('msg', res.data)
                     window.location = "/activate"                   
                  }
            }, err => {
                 this.setState({error: err+""})
                this.setLoader(false)
            }
        )
    }
    processDate(date)
    {
      const now = new Date(date);
      const todayY = now.getFullYear();
      const todayM = checkZero(now.getMonth() + 1);
      const todayD = checkZero(now.getDate());
      var date1 = todayY + '-' + todayM + '-' + todayD;
      console.log(date1)
     return date1;   
    }
    setLoader(loader)
    {
     this.setState({loader: loader})
    }
    render() {
        let divMsg = ''
        if (this.state.error) {
            divMsg = (<div className='alert alert-danger'>{this.state.error}</div>)
        }
        let loaderDiv = ''
        if(this.state.loader)
           {
            loaderDiv = (  <div id="loader" className="loader-overlay"><div align="center" className="loader"></div></div>)
           }
        return (
            <div className='d-flex' id='wrapper'>
                <div id='page-content-wrapper'>
                    <Banner />
                        <br />
                    <div className="container-fluid">
                        <div className="user-form">
                    <div  id="form">
                        <nav style={{'--bs-breadcrumb-divider': '>'}} aria-label="breadcrumb">
                                    <ol class="breadcrumb">
                                        <li class="breadcrumb-item"><a href="/login">Login</a></li>
                                        <li class="breadcrumb-item"><i class="bi bi-chevron-right"></i></li>
                                        <li class="breadcrumb-item active" aria-current="page">Create Account</li>
                                    </ol>
                                    </nav>
                        {divMsg}
                        <div className="row">
                                                <div className="col"><div className="input-group has-validation">
                                                    <span className="input-group-text">Salutation</span>
                                                    <select className={this.state.salutationError !== "" ? "form-control is-invalid" : "form-control"} value={this.state.salutationId} onChange={e => { this.setState({salutationId: e.target.value}); this.doValidate() }} >
                                                        <option value="">Choose Salutation</option>
                                                        {this.state.salutations.map((salutation, index) => (
                                                            <option key={index} value={salutation.id} >{salutation.name}</option>
                                                        ))}
                                                    </select><div className="invalid-feedback">{this.state.salutationError}</div>
                                                </div></div>
                                                </div>
                                                <div className="row">
                                                <div className="col"><div className="input-group has-validation">
                                                    <span className="input-group-text">Firstname</span>
                                                    <input type="text" className={this.state.firstnameError !== "" ? "form-control is-invalid" : "form-control"} value={this.state.firstname} onChange={e => { this.setState({firstname: (e.target.value).toLocaleUpperCase()}); this.doValidate() }} />
                                                    <div className="invalid-feedback">{this.state.firstnameError}</div>
                                                </div></div>
                                                </div>
                                                <div className="row">
                                                <div className="col"><div className="input-group has-validation">
                                                    <span className="input-group-text">Surname</span>
                                                    <input type="text" className={this.state.surnameError !== "" ? "form-control is-invalid" : "form-control"} value={this.state.surname} onChange={e => { this.setState({surname: (e.target.value).toLocaleUpperCase()}); this.doValidate() }} />
                                                    <div className="invalid-feedback">{this.state.surnameError}</div>
                                                </div></div>
                                            </div>
                                            <div className="row">
                                                <div className="col"><div className="input-group has-validation">
                                                    <span className="input-group-text">Gender</span>
                                                    <select className={this.state.genderError !== "" ? "form-control is-invalid" : "form-control"} value={this.state.gender} onChange={e => { this.setState({gender: e.target.value}); this.doValidate()}} >
                                                        <option value="">Choose Gender</option>
                                                        {this.state.genders.map((gender, index) => (
                                                            <option key={index} value={gender} >{gender}</option>
                                                        ))}
                                                    </select>
                                                    <div className="invalid-feedback">{this.state.genderError}</div>
                                                </div></div>
                                                </div>
                                                <div className="row">
                                                <div className="col"><div className="input-group has-validation">
                                                    <span className="input-group-text">Date of Birth (yyyy-mm-dd)</span>
                                                    <DatePicker className={this.state.dateOfBirthError !== "" ? "form-control is-invalid" : "form-control"} selected={new Date(this.state.dateOfBirth)} maxDate={new Date()} onChange={d => { this.setState({dateOfBirth: this.processDate(d)}); this.doValidate() }} dateFormat="yyyy-MM-dd" showYearDropdown yearDropdownItemNumber={80} scrollableYearDropdown />
                                                    <input type="hidden" className={this.state.dateOfBirthError !== "" ? "form-control is-invalid" : "form-control"} /><div className="invalid-feedback">{this.state.dateOfBirthError}</div>
                                                </div></div>
                                                </div>
                                                <div className="row">
                                                <div className="col"><div className="input-group has-validation">
                                                    <span className="input-group-text">Email</span>
                                                    <input type="text" className={this.state.emailError !== "" ? "form-control is-invalid" : "form-control"} value={this.state.email} onChange={e => { this.setState({email: e.target.value}); this.doValidate() }} />
                                                    <div className="invalid-feedback">{this.state.emailError}</div>
                                                </div></div>
                                            </div>
                                            <div className="row">
                                                <div className="col"><div className="alert alert-info">Please enter a mobile number that can receive whatsapp or sms messages</div><div className="input-group">
                                                    <span className="input-group-text"> Mobile Number 263</span>
                                                    <input type="text" className={this.state.mobileError !== "" ? "form-control is-invalid" : "form-control"} value={this.state.mobile} onChange={e => { this.setState({mobile: e.target.value}); this.doValidate() }} />
                                                    <div className="invalid-feedback">{this.state.mobileError}</div> </div></div>
                                                </div>
                                            <div className="row">
                                                <div className="col"><div className="input-group has-validation">
                                                    <span className="input-group-text">OTP via</span>
                                                    <select className={this.state.typeError !== "" ? "form-control is-invalid" : "form-control"} value={this.state.type} onChange={e => { this.setState({type: e.target.value}); this.doValidate()}} >
                                                        <option value="">Choose </option>
                                                        <option  value="SMS" >SMS</option>
                                                        <option  value="WHATSAPP" >WHATSAPP</option>
                                                    </select>
                                                    <div className="invalid-feedback">{this.state.typeError}</div>
                                                </div></div>
                                                </div>
                                                <div className="row">
                                                <div className="col"><div className="input-group">
                                                    <span className="input-group-text">Pincode</span>
                                                    <input type="password" className={this.state.pincodeError !== "" ? "form-control is-invalid" : "form-control"} value={this.state.pincode} onChange={e => { this.setState({pincode: e.target.value}); this.doValidate() }} />
                                                    <div className="invalid-feedback">{this.state.pincodeError}</div> </div></div>
                                                </div>
                                                <div className="row">
                                                <div className="col"><div className="input-group">
                                                    <span className="input-group-text">Verify Pincode</span>
                                                    <input type="password" className={this.state.pincode1Error !== "" ? "form-control is-invalid" : "form-control"} value={this.state.pincode1} onChange={e => { this.setState({pincode1: e.target.value}); this.doValidate() }} />
                                                    <div className="invalid-feedback">{this.state.pincode1Error}</div> </div></div>
                                            </div>
                             <div className="row">
                                <div className="col-12">
                                <div className="d-grid gap-2"><button disabled={this.state.showSubmit === false} onClick={() => this.signup()}  className="btn btn-primary" >Signup</button></div>
                                    </div>
                                </div>

                         </div>
                         </div>
                        {loaderDiv}
                    </div>

                </div>
            </div>
        )

    }



}