import React, { Component } from 'react'
import axios from 'axios'
import Banner from './banner'
let intv = null
export default class Activate extends Component {
    state={error: '', msg: '', loaderDiv: false, mobile: localStorage.getItem('mobile')?localStorage.getItem('mobile'):'', mobileError: '', otpcode: '', otpcodeError: '', ipAddress: '', country: '', isp: '', showSubmit: false, counter: 60, type: ''}
    componentDidMount() {
        var msg = localStorage.getItem('msg') && localStorage.getItem('msg') !== ""?localStorage.getItem('msg'):""
        if(msg !== null && msg !== "")
            {
                localStorage.setItem('msg', '')
            }
            if(intv !== null)
                {
                   clearInterval(intv);
                }
                intv = setInterval(() =>{ this.state.counter >= 0 && this.setState({counter: this.state.counter - 1})} , 1000);     
     this.setState({msg: msg})
    }
    doValidate() {
        setTimeout(() => {
            this.validate();
        }, 1000);
    }
    
    validate() {
           
        var error = false;
       
        if (this.state.otpcode === null) {
            this.setState({ otpcodeError: "OTP code cannot be empty" })
            error = true
        }
        else if (this.state.otpcode === "") {
            this.setState({ otpcodeError: "OTP code cannot be empty" })
            error = true
        }
        else if(!(/^(\d{6})$/.test(this.state.otpcode)))
            {
            this.setState({ otpcodeError: "OTP code should be 6 digits only"})
            error = true
            }
        else {
            this.setState({ otpcodeError: "" })
        }
        if(error === true)
               {
                this.setState({ error: "One or more of the mandatory field(s) is not filled in", showSubmit: false}) 
               }
            else if (this.state.counter >= 0)
               {
                this.setState({error: "", showSubmit: true}) 
               }   
    }
    verify() {
        this.setState({ error: '' , msg:''});
        this.setLoader(true)
        axios.get(localStorage.getItem('host') + '/login/verify/'+this.state.mobile+'/'+this.state.otpcode).then(
            res => {
                this.setLoader(false)
                if (res.data.includes('Error')) {
                    this.setState({ error: res.data });
                  }
                  else {
                   
                     localStorage.setItem('msg', res.data)
                      window.location = '/login'
                   
                  }
            }, err => {
                 this.setState({error: err+""})
                this.setLoader(false)
            }
        )
    }
    resend(type) {
        this.setState({ error: '' });
        this.setLoader(true)
        axios.get(localStorage.getItem('host') + '/login/resendotp/'+type+'/'+this.state.mobile).then(
            res => {
                this.setLoader(false)
                if (res.data.includes('Error')) {
                    this.setState({ error: res.data });
                  }
                  else {
                   
                      this.setState({msg: res.data, counter: 60})
                      if(intv !== null)
                         {
                            clearInterval(intv);
                         }
                  intv = setInterval(() =>{ this.state.counter >= 0 && this.setState({counter: this.state.counter - 1})} , 1000); 
                  }
            }, err => {
                 this.setState({error: err+""})
                this.setLoader(false)
            }
        )
    }
    setLoader(loader)
    {
     this.setState({loader: loader})
    }
    render() {
        let divMsg = ''
        if (this.state.error) {
            divMsg = (<div className='alert alert-danger'>{this.state.error}</div>)
        }
        if (this.state.msg) {
            divMsg = (<div className='alert alert-success'>{this.state.msg}</div>)
        }
        let loaderDiv = ''
        if(this.state.loader)
           {
            loaderDiv = (  <div id="loader" className="loader-overlay"><div align="center" className="loader"></div></div>)
           }
        return (
            <div className='d-flex' id='wrapper'>
                <div id='page-content-wrapper'>
                        <Banner />
                        <br />
                        <div className="container-fluid">
                    <div className="user-form">
                    <div  id="form">
                        <h3>Activate Account</h3>
                            {divMsg}
                             <div className="row">
                                <div className="col-12">
                                    <div className="input-group">
                                    <span className="input-group-text">Mobile Number 263</span>
                                                 <input type="text" className="form-control" value={this.state.mobile}  disabled={true} />
                                                 </div>
                                    </div>
                                </div>
                             <div className="row">
                                <div className="col-12">
                                    <div className="input-group">
                                    <span className="input-group-text">OTP code</span>
                                                 <input type="password" className={this.state.otpcodeError !== "" ? "form-control is-invalid" : "form-control"} value={this.state.otpcode} onChange={e => { this.setState({otpcode: e.target.value}); this.doValidate() }} />
                                                 <div className="invalid-feedback">{this.state.otpcodeError}</div></div>
                                    </div>
                                </div>
                            <div className="row">
                            {this.state.counter > -1 &&    <div className="col"><div className={this.state.counter < 30 ? "alert alert-danger" : "alert alert-warning"}><b>Time Remaining:</b> {this.state.counter} Seconds</div></div>}</div> 
                             <div className="row">
                                <div className="col-12">
                                <div className="d-grid gap-2"><button disabled={this.state.showSubmit === false} onClick={() => this.verify()}  className="btn btn-primary" >Verify</button></div>
                                    </div>
                                </div>
                             <div className="row">
                                <div className="col">
                                <div className="d-grid gap-2"><button  disabled={this.state.counter > 0} onClick={() => this.resend('SMS')}  className="btn btn-primary" >Resend via SMS</button></div>
                                    </div>
                                    </div>
                             <div className="row">      
                                    <div className="col">
                                <div className="d-grid gap-2"><button  disabled={this.state.counter > 0} onClick={() => this.resend('WHATSAPP')}  className="btn btn-primary" >Resend via Whatsapp</button></div>
                                    </div>
                                </div>
                             </div>
                             {loaderDiv}
                        </div>
                    </div>

                </div>
            </div>
        )

    }



}