import React, { Component } from 'react'
import Banner from './banner'
export default class Index extends Component {
    componentDidMount()
    {

    if(localStorage.getItem('mobile') && localStorage.getItem('mobile') !== null)
        {
         window.location.href = "/login"
        }
    }
    openLogin() {
        window.location = '/login'
    }
    openSingup() {
        window.location = '/signup'
    }
    render() {
        return (
            <div className='d-flex' id='wrapper'>
                <div id='page-content-wrapper'>
                    <Banner />
                    <br />
                    <div className="container-fluid">
                          <div className="user-form">
                            <div  id="form">
                            <h3>Welcome To Ucare</h3>
                                <p>Please login to access your account, or signup to get started. Ucare will help you get the best medical healthcare and bring healthcare facilities to your finger tips</p>
                                <div className="row ">
                                    <div className="col-12">  <div class="d-grid gap-2">
                                        <button className="btn btn-primary" onClick={() => this.openLogin()} >Login</button>

                                    </div> </div>
                                </div>
                                <div className="row ">
                                    <div className="col-12"> <div class="d-grid gap-2"><button className="btn btn-primary" onClick={() => this.openSingup()} >Signup</button></div></div>
                                </div>
                                <div className="row">
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        )

    }



}