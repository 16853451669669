import React, { Component } from 'react'
import axios from 'axios'
import Banner from './banner';
let intv = null
export default class Forgot extends Component {
state={error: '', msg: '', loaderDiv: false, mobile: localStorage.getItem('mobile')?localStorage.getItem('mobile'):'', mobileError: '',  otpcode: '', otpcodeError: '', pincode: '', pincodeError: '', pincode1: '', pincode1Error: '', ipAddress: '', country: '', isp: '', showSubmit: false, counter: -1}

componentDidMount() {
    var msg = localStorage.getItem('msg') && localStorage.getItem('msg') !== ""?localStorage.getItem('msg'):""
    if(msg !== null && msg !== "")
        {
            localStorage.setItem('msg', '')
        }
 this.setState({msg: msg})
    axios.get('https://api.ipdata.co?api-key=212ad9acd89c4d7e42b8b70db2317c75e051aa7f16c81fed265ef9bd').then(res => {
      this.setState({ ipAddress: res.data.ip, country: res.data.country_name, isp: res.data.asn.name });
      console.log(" ............." + res.data.ip + "...." + res.data.country_name + "" + res.data.asn.name + "")
    }, err => {
      console.log(".....")
    })
   
  }

doValidate() {
    setTimeout(() => {
        this.validate();
    }, 1000);
}

validate() {
       
    var error = false;
    if (this.state.mobile === null) {
        this.setState({ mobileError: "Mobile Number cannot be empty" })
        error = true
    }
    else if (this.state.mobile === "") {
        this.setState({ mobileError: "Mobile Number cannot be empty" })
        error = true
    }
    else if(!(/^[7]\d{8}$/.test(this.state.mobile)))
        {
        this.setState({ mobileError: "Please numbers only should not start with zero like 772747211"})
        error = true
        }
    else {
        this.setState({ mobileError: "" })
    }
    if (this.state.otpcode === null) {
        this.setState({ otpcodeError: "OTP code cannot be empty" })
        error = true
    }
    else if (this.state.otpcode === "") {
        this.setState({ otpcodeError: "OTP code cannot be empty" })
        error = true
    }
    else if(!(/^(\d{6})$/.test(this.state.otpcode)))
        {
        this.setState({ otpcodeError: "OTP code should be 6 digits only"})
        error = true
        }
    else {
        this.setState({ otpcodeError: "" })
    }
    if (this.state.pincode === null) {
        this.setState({ pincodeError: "Pincode cannot be empty" })
        error = true
    }
    else if (this.state.pincode === "") {
        this.setState({ pincodeError: "Pincode cannot be empty" })
        error = true
    }
    else if(!(/^(\d{4})$/.test(this.state.pincode)))
        {
        this.setState({ pincodeError: "Pincode should be 4 digits only"})
        error = true
        }
    else {
        this.setState({ pincodeError: "" })
    }

    if (this.state.pincode1 === null) {
        this.setState({ pincode1Error: "Pincode cannot be empty" })
        error = true
    }
    else if (this.state.pincode1 === "") {
        this.setState({ pincode1Error: "Pincode cannot be empty" })
        error = true
    }
    else if(!(/^(\d{4})$/.test(this.state.pincode1)))
        {
        this.setState({ pincode1Error: "Pincode should be 4 digits only"})
        error = true
        }
    else if(this.state.pincode1 !== this.state.pincode){
        this.setState({ pincode1Error: "Pincodes do not match" , pincodeError: "Pincodes do not match" })
    }
    else {
        this.setState({ pincode1Error: "" })
    }
    if(error === true)
           {
            this.setState({ error: "One or more of the mandatory field(s) is not filled in", showSubmit: false}) 
           }
        else if (this.state.counter >= 0)
           {
            this.setState({error: "", showSubmit: true}) 
           }   
}
reset() {
    this.setState({ error: '' });
    this.setLoader(true)
    axios.get(localStorage.getItem('host') + '/login/reset/'+this.state.mobile+'/'+this.state.otpcode+'/'+this.state.pincode).then(
        res => {
            this.setLoader(false)
            if (res.data.includes('Error')) {
                this.setState({ error: res.data });
              }
              else {               
                localStorage.setItem('msg', res.data)
                window.location = "/login"                
              }
        }, err => { console.log(err+"")
             this.setState({error: err+""})
            this.setLoader(false)
        }
    )
}
getOTP(type) {
    this.setState({ error: '' });
    this.setLoader(true)
    axios.get(localStorage.getItem('host') + '/login/resendotp/'+type+'/'+this.state.mobile).then(
        res => {
            this.setLoader(false)
            if (res.data.includes('Error')) {
                this.setState({ error: res.data });
              }
              else {
               
                this.setState({msg: "Successfully sent OTP code to your phone", counter: 60})
                if(intv !== null)
                   {
                      clearInterval(intv);
                   }
                   intv = setInterval(() =>{this.state.counter >= 0 &&  this.setState({counter: this.state.counter - 1})} , 1000);
              }
        }, err => {
             this.setState({error: err+""})
            this.setLoader(false)
        }
    )
}
setLoader(loader)
{
 this.setState({loader: loader})
}
    render() {
        let divMsg = ''
        if (this.state.error) {
            divMsg = (<div className='alert alert-danger'>{this.state.error}</div>)
        }
        if (this.state.msg) {
            divMsg = (<div className='alert alert-success'>{this.state.msg}</div>)
        }
        let loaderDiv = ''
        if(this.state.loader)
           {
            loaderDiv = (  <div id="loader" className="loader-overlay"><div align="center" className="loader"></div></div>)
           }
        return (
            <div className='d-flex' id='wrapper'>
                <div id='page-content-wrapper'>
                        <Banner />
                        <br />
                    <div className="container-fluid">
                    <div className="user-form">
                    <div  id="form">
                        <h3>Reset</h3>
                            {divMsg}
                             <div className="row">
                                <div className="col-12">
                                    <div className="input-group">
                                    <span className="input-group-text">Mobile Number 263</span>
                                                 <input type="text" className={this.state.mobileError !== "" ? "form-control is-invalid" : "form-control"} value={this.state.mobile} onChange={e => { this.setState({mobile: e.target.value}); this.doValidate() }} disabled={true} />
                                                 <div className="invalid-feedback">{this.state.mobileError}</div></div>
                                    </div>
                                </div>
                             <div className="row">
                                <div className="col-12">
                               <div className="d-grid gap-2"><button disabled={this.state.counter > 0} onClick={() => this.getOTP('WHATSAPP')}  className="btn btn-primary" >Get OTP via Whatsapp</button></div>
                                    </div>
                                </div>
                             <div className="row">
                                <div className="col-12">
                               <div className="d-grid gap-2"><button disabled={this.state.counter > 0} onClick={() => this.getOTP('SMS')}  className="btn btn-primary" >Get OTP via SMS</button></div>
                                    </div>
                                </div>
                            <div className="row">
                            {this.state.counter > -1 &&    <div className="col"><div className={this.state.counter < 30 ? "alert alert-danger" : "alert alert-warning"}><b>Time Remaining:</b> {this.state.counter} Seconds</div></div>}</div>   
                             <div className="row">
                                <div className="col-12">
                                    <div className="input-group">
                                    <span className="input-group-text">OTP code</span>
                                                 <input type="password" className={this.state.otpcodeError !== "" ? "form-control is-invalid" : "form-control"} value={this.state.otpcode} onChange={e => { this.setState({otpcode: e.target.value}); this.doValidate() }} />
                                                 <div className="invalid-feedback">{this.state.otpcodeError}</div></div>
                                    </div>
                                </div>
                                                <div className="row">
                                                <div className="col"><div className="input-group">
                                                    <span className="input-group-text">Pincode</span>
                                                    <input type="password" className={this.state.pincodeError !== "" ? "form-control is-invalid" : "form-control"} value={this.state.pincode} onChange={e => { this.setState({pincode: e.target.value}); this.doValidate() }} />
                                                    <div className="invalid-feedback">{this.state.pincodeError}</div> </div></div>
                                                </div>
                                                <div className="row">
                                                <div className="col"><div className="input-group">
                                                    <span className="input-group-text">Verify Pincode</span>
                                                    <input type="password" className={this.state.pincode1Error !== "" ? "form-control is-invalid" : "form-control"} value={this.state.pincode1} onChange={e => { this.setState({pincode1: e.target.value}); this.doValidate() }} />
                                                    <div className="invalid-feedback">{this.state.pincode1Error}</div> </div></div>
                                            </div>
                             <div className="row">
                                <div className="col-12">
                                <div className="d-grid gap-2"><button disabled={this.state.showSubmit === false} onClick={() => this.reset()}  className="btn btn-primary" >Reset Pin</button></div>
                                    </div>
                                </div>
                             </div>
                        </div>
                       </div>
                        {loaderDiv}

                </div>
            </div>
        )

    }



}