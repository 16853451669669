import React, { Component } from 'react'
import axios from 'axios'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Top from './top';
function checkZero(num) {
    console.log((num + "").length)
    return (num + "").length === 1 ? "0" + num : num;
}
export default class UpdateDetails extends Component {
    state = { error: '', msg: '', patientId: 0, loaderDiv: false, mobile: localStorage.getItem('mobile') ? localStorage.getItem('mobile') : '', general: true, contact: false, nextOfKin: false, insurance: false,
    firstname: '', firstnameError: '', salutationId: '', salutationError: '', idNumber: '', middlename: '', email: '',
    surname: '', surnameError: '', passport: '', gender: '', genderError: '', dateOfBirth: "1970-01-02", dateOfBirthError: '', employer: '', employerError: '', fileNumber: '', salutations: [], genders: [],
    address1: '', address1Error: '', address2: '', address2Error: '', town: '', townError: '', landline: '', towns: [],
    nextOfKinName: '', nextOfKinNameError: '', nextOfKinRelationshipId: '', nextOfKinRelationshipIdError: '', nextOfKinMobile: '', nextOfKinMobileError: '', relationships: [],
    patientInsurances: [], insurances: [], suffixs: [], dependents: [], sourceofVisits: [], sourceofVisit: '', remarks: '', showSubmit: false}

    componentDidMount() {
        this.setLoader(true)
        axios.get(localStorage.getItem('host') + '/auth/userbyid/' + sessionStorage.getItem('userId'), {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setLoader(false)
                    this.setState({patientId: res.data.patientId, firstname: res.data.firstname, idNumber: res.data.idNumber, middlename: res.data.middlename, surname: res.data.surname, salutationId: res.data.salutationId, passport:  res.data.passport, gender:  res.data.gender, dateOfBirth:  res.data.dateOfBirth, employer:  res.data.employer, address1:  res.data.address1, address2:  res.data.address2, town:  res.data.town, email:  res.data.email, landline:  res.data.landline, nextOfKinName:  res.data.nextOfKinName,nextOfKinRelationshipId:  res.data.nextOfKinRelationshipId, nextOfKinMobile: res.data.nextOfKinMobile 
                    , salutations: res.data.salutations, relationships: res.data.relationships, insurances: res.data.insurances, suffixs: res.data.suffixs, genders: res.data.genders, patientInsurances: res.data.patientInsurances});
                this.doValidate();
                 }, err => {
                this.setState({ error: err + "" })
                this.setLoader(false)
            }
        )
    }

    setPatientValue(name, value) {
        switch (name) {
            case "salutationId":
                this.setState({ salutationId: value })
                console.log(value)
                break;
            case "firstname":
                this.setState({ firstname: (value).toLocaleUpperCase() })
                break;
            case "idNumber":
                this.setState({ idNumber: (value).toLocaleUpperCase() })
                break;
            case "middlename":
                this.setState({ middlename: (value).toLocaleUpperCase() })
                break;
            case "surname":
                this.setState({ surname: (value).toLocaleUpperCase() })
                break;
            case "passport":
                this.setState({ passport: (value).toLocaleUpperCase() })
                break;
            case "gender":
                this.setState({ gender: value })
                break;
            case "dateOfBirth": 
                this.setState({ dateOfBirth: this.processDate(value)})
                break;
            case "employer":
                this.setState({ employer: (value).toLocaleUpperCase() })
                break;
            case "fileNumber":
                this.setState({ fileNumber: value })
                break;
            case "address1":
                this.setState({ address1: (value).toLocaleUpperCase() })
                break;
            case "address2":
                this.setState({ address2: (value).toLocaleUpperCase() })
                break;
            case "town":
                this.setState({ town: (value).toLocaleUpperCase() })
                break;
            case "email":
                this.setState({ email: value })
                break;
            case "landline":
                this.setState({ landline: value })
                break;
            case "nextOfKinName":
                this.setState({ nextOfKinName: (value).toLocaleUpperCase() })
                break;
            case "nextOfKinRelationshipId":
                this.setState({ nextOfKinRelationshipId: value })
                break;
            case "nextOfKinMobile":
                this.setState({ nextOfKinMobile: value })
                break;
                default:
                    console.log("..............")
                    break

        }
        this.doValidate()
    }
    doValidate() {
        setTimeout(() => {
            this.validatePatient();
        }, 1000);
    }
    validatePatient() {
       
        var error = false;
        if (this.state.firstname === null) {
            this.setState({ firstnameError: "Firstname cannot be empty" });
            error = true
        }
        else if (this.state.firstname === "") {
            this.setState({ firstnameError: "Firstname cannot be empty" });
            error = true
        }
        else if(!(/^(?!\s*$)[-a-zA-Z ]*$/.test(this.state.firstname)))
            {
            this.setState({ firstnameError: "Firstname should contain characters only and no spaces"})
            error = true
            }
        else {
            this.setState({ firstnameError: "" })
        }

        if (this.state.surname === null) {
            this.setState({ surnameError: "Surname cannot be empty" })
            error = true
        }
        else if (this.state.surname === "") {
            this.setState({ surnameError: "Surname cannot be empty" })
            error = true
        }
        else if(!(/^(?!\s*$)[-a-zA-Z ]*$/.test(this.state.surname)))
            {
            this.setState({ surnameError: "Surname should contain characters only and no spaces"})
            error = true
            }
        else {
            this.setState({ surnameError: "" })
        }

        if (this.state.salutationId === 0) {
            this.setState({ salutationError: "Salutation cannot be empty" })
            error = true
        }
        else if (this.state.salutationId === "") {
            this.setState({ salutationError: "Salutation cannot be empty" })
            error = true
        }
        else {
            this.setState({ salutationError: "" })
        }
        if (this.state.gender === null) {
            this.setState({ genderError: "Gender cannot be empty" })
            error = true
        }
        else if (this.state.gender === "") {
            this.setState({ genderError: "Gender cannot be empty" })
            error = true
        }
        else {
            this.setState({ genderError: "" })
        } console.log(this.state.dateOfBirth)
        if (this.state.dateOfBirth === null) {
            this.setState({ dateOfBirthError: "Date Of Birth cannot be empty" })
            error = true
        }
        else if (this.state.dateOfBirth === "") {
            this.setState({ dateOfBirthError: "Date Of Birth cannot be empty" })
            error = true
        }
        else if(!(/^(\d{4})-(\d{2})-(\d{2})$/.test(this.state.dateOfBirth)))
            {
            this.setState({ dateOfBirthError: "Date Of Birth wrong format"})
            error = true
            }
        else {
            this.setState({ dateOfBirthError: "" })
        }
        if (this.state.employer === null) {
            this.setState({ employerError: "Employer cannot be empty" })
            error = true
        }
        else if (this.state.employer === "") {
            this.setState({ employerError: "Employer cannot be empty" })
            error = true
        }
        else {
            this.setState({ employerError: "" })
        }
        if (this.state.address1 === null) {
            this.setState({ address1Error: "Street / Flat / Building Number cannot be empty" })
            error = true
        }
        else if (this.state.address1 === "") {
            this.setState({ address1Error: "Street / Flat / Building Number cannot be empty" })
            error = true
        }
        else {
            this.setState({ address1Error: "" })
        }
        if (this.state.address2 === null) {
            this.setState({ address2Error: "Surburb cannot be empty" })
            error = true
        }
        else if (this.state.address2 === "") {
            this.setState({ address2Error: "Surburb cannot be empty" })
            error = true
        }
        else {
            this.setState({ address2Error: "" })
        }
        if (this.state.town === null) {
            this.setState({ townError: "Town cannot be empty" })
            error = true
        }
        else if (this.state.town === "") {
            this.setState({ townError: "Town cannot be empty" })
            error = true
        }
        else {
            this.setState({ townError: "" })
        }
        if (this.state.nextOfKinName === null) {
            this.setState({ nextOfKinNameError: "Next of Kin Name cannot be empty" })
            error = true
        }
        else if (this.state.nextOfKinName === "") {
            this.setState({ nextOfKinNameError: "Next of Kin Name cannot be empty" })
            error = true
        }
        else {
            this.setState({ nextOfKinNameError: "" })
        }
        if (this.state.nextOfKinRelationshipId === 0) {
            this.setState({ nextOfKinRelationshipIdError: "Relationship to Patient cannot be empty" })
            error = true
        }
        else if (this.state.nextOfKinRelationshipId === "") {
            this.setState({ nextOfKinRelationshipIdError: "Relationship to Patient cannot be empty" })
            error = true
        }
        else {
            this.setState({ nextOfKinRelationshipIdError: "" })
        }
        if (this.state.nextOfKinMobile === null) {
            this.setState({ nextOfKinMobileError: "Next of Kin Mobile cannot be empty" })
            error = true
        }
        else if (this.state.nextOfKinMobile === "") {
            this.setState({ nextOfKinMobileError: "Next of Kin Mobile cannot be empty" })
            error = true
        }
        else {
            this.setState({ nextOfKinMobileError: "" })
        }

        var patientInsurances = this.state.patientInsurances;
        for (var index in patientInsurances) {
            if (patientInsurances[index].insuranceId === "") {
                patientInsurances[index].insuranceIdError = "Insurance cannot be empty"
                error = true
            }
            else {
                patientInsurances[index].insuranceIdError = "";
            }
            if (patientInsurances[index].relationshipId === "" || patientInsurances[index].relationshipId === "0" || patientInsurances[index].relationshipId === 0) {
                patientInsurances[index].relationshipIdError = "Relationship to Member cannot be empty"
                error = true
            } //pattern-custom="^[7]\d{8}$"  data-pattern-error="Please numbers only should not start with zero like 772747211"
            else {
                patientInsurances[index].relationshipIdError = "";
            }
            if (patientInsurances[index].suffixId === "" || patientInsurances[index].suffixId === "0" || patientInsurances[index].suffixId === 0) {
                patientInsurances[index].suffixIdError = "Suffix cannot be empty"
                error = true
            }
            else {
                patientInsurances[index].suffixIdError = "";
            }
            if (patientInsurances[index].memberName === "") {
                patientInsurances[index].memberNameError = "Member Number cannot be empty"
                error = true
            }
            else {
                patientInsurances[index].memberNameError = "";
            }
            if (patientInsurances[index].memberNumber === "") {
                patientInsurances[index].memberNumberError = "Member Name cannot be empty"
                error = true
            }
            else {
                patientInsurances[index].memberNumberError = "";
            }
        }
        console.log(" patientInsurances [] "+patientInsurances)
        this.setState({ patientInsurances: patientInsurances })
        if(error === true)
           {
            this.setState({ error: "One or more of the mandatory field(s) is not filled in", showSubmit: false}) 
           }
        else
           {
            this.setState({error: "", showSubmit: true}) 
           }   
    }
    setPatientInsuranceValue(index, name, value) {
        let list = this.state.patientInsurances.slice();

        switch (name) {
            case "insuranceId":
                list[index].insuranceId = value;
                break;
            case "relationshipId":
                list[index].relationshipId = value;
                break;
            case "suffixId":
                list[index].suffixId = value;
                break;
            case "memberName":
                list[index].memberName = (value).toLocaleUpperCase();
                break;
            case "memberNumber":
                list[index].memberNumber = value;
                break;
            default:
                console.log('....')
                break;
        }
        this.setState({ patientInsurances: list });
        this.validatePatient()        
    }
    addInsuranceTable()
    {
        let list = this.state.patientInsurances.slice();
        list.push({ id: 0, insuranceId: '', insuranceIdError: "Insurance cannot be empty", relationshipId: '', relationshipIdError: "Relationship to Member cannot be empty", suffixId: '', suffixIdError: "Suffix cannot be empty", memberNumber: '', memberNumberError: "Member Number cannot be empty", memberName: '', memberNameError: "Member Name cannot be empty", activeStatus: 1 });
        this.setState({ patientInsurances: list });
        this.doValidate()
    }
    removePatientInsuranceEntry(index)
    {
        let list = this.state.patientInsurances.slice();
        list.splice(index, 1)
        this.setState({ patientInsurances: list }); 
        this.doValidate()
    }
    
save() {
    this.setLoader(true)
    var data = {id: this.state.id, patientId: this.state.patientId, firstname: this.state.firstname, salutationId: this.state.salutationId,  idNumber: this.state.idNumber, middlename: this.state.middlename,
        surname: this.state.surname, passport: this.state.passport, gender: this.state.gender, dateOfBirth: this.state.dateOfBirth, employer: this.state.employer, fileNumber: this.state.fileNumber, 
        address1: this.state.address1, address2: this.state.address2, town: this.state.town, landline: this.state.landline, email: this.state.email, 
        nextOfKinName: this.state.nextOfKinName, nextOfKinRelationshipId: this.state.nextOfKinRelationshipId, nextOfKinMobile: this.state.nextOfKinMobile,
        patientInsurances: this.state.patientInsurances}
    axios.post(localStorage.getItem('host') + '/auth/updateuser', data, {
        headers: {
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        }
    }).then(
        res => {
            this.setLoader(false)
            console.log(res.data)
            if(res.data.includes("Error"))
              {
                this.setState({ error: res.data });  
              }
              else
              {
                this.setState({ msg: res.data });  
              }
        }, err => {
             this.setState({error: err+""})
            this.setLoader(false)
        }
    )
}
processDate(date)
{
  const now = new Date(date);
  const todayY = now.getFullYear();
  const todayM = checkZero(now.getMonth() + 1);
  const todayD = checkZero(now.getDate());
  var date1 = todayY + '-' + todayM + '-' + todayD;
  console.log(date1)
 return date1;
  

}
    setLoader(loader) {
        this.setState({ loader: loader })
    }
    toggleTabs(name) {
        switch (name) {
            case "contact":
                this.setState({ general: false, contact: true, nextOfKin: false, insurance: false })
                break
            case "nextOfKin":
                this.setState({ general: false, contact: false, nextOfKin: true, insurance:false })
                break
            case "insurance":
                this.setState({ general: false, contact: false, nextOfKin: false , insurance: true})
                break
            default:
                this.setState({ general: true, contact: false, nextOfKin: false, insurance: false })
                break

        }
    }
    render() {
        let divMsg = ''
        if (this.state.error) {
            divMsg = (<div className='alert alert-danger'>{this.state.error}</div>)
        }
        if (this.state.msg) {
            divMsg = (<div className='alert alert-success'>{this.state.msg}</div>)
        }
        let loaderDiv = ''
        if (this.state.loader) {
            loaderDiv = (<div id="loader" className="loader-overlay"><div align="center" className="loader"></div></div>)
        }
        return (
            <div className='d-flex' id='wrapper'>
                <div id='page-content-wrapper'>
                    <Top />
                    <br />
                    <div className="container-fluid">
                    <div className="user-form">
                    <div  id="form">
                            <nav style={{'--bs-breadcrumb-divider': '>'}} aria-label="breadcrumb">
                                    <ol class="breadcrumb">
                                        <li class="breadcrumb-item"><a href="/home">Home</a></li>
                                        <li class="breadcrumb-item"><i class="bi bi-chevron-right"></i></li>
                                        <li class="breadcrumb-item active" aria-current="page">Update My Details</li>
                                    </ol>
                                    </nav>
                                {divMsg}

                                <div class="accordion" id="accordionPanelsStayOpenExample">
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="panelsStayOpen-headingOne">
                                            <button className={(this.state.general ? '' : 'collapsed') + ' accordion-button'} onClick={()=> this.toggleTabs('general')}>General Details</button>
                                        </h2>
                                        <div className={(this.state.general ? 'show' : '') + ' accordion-collapse collapse'}>
                                            <div class="accordion-body">
                                            <div className="row">
                                                <div className="col"><div className="input-group has-validation">
                                                    <span className="input-group-text">Salutation</span>
                                                    <select className={this.state.salutationError !== "" ? "form-control is-invalid" : "form-control"} value={this.state.salutationId} onChange={e => { this.setPatientValue('salutationId', e.target.value); }} >
                                                        <option value="">Choose Salutation</option>
                                                        {this.state.salutations.map((salutation, index) => (
                                                            <option key={index} value={salutation.id} >{salutation.name}</option>
                                                        ))}
                                                    </select><div className="invalid-feedback">{this.state.salutationError}</div>
                                                </div></div>
                                                </div>
                                                <div className="row">
                                                <div className="col"><div className="input-group has-validation">
                                                    <span className="input-group-text">Firstname</span>
                                                    <input type="text" className={this.state.firstnameError !== "" ? "form-control is-invalid" : "form-control"} value={this.state.firstname} onChange={e => { this.setPatientValue('firstname', e.target.value); }} />
                                                    <div className="invalid-feedback">{this.state.firstnameError}</div>
                                                </div></div>
                                                </div>
                                            <div className="row">
                                                <div className="col"><div className="input-group">
                                                    <span className="input-group-text"> Middlenames</span>
                                                    <input type="text" className="form-control" value={this.state.middlename} onChange={e => { this.setPatientValue('middlename', e.target.value); }} />
                                                </div></div>
                                                </div>
                                                <div className="row">
                                                <div className="col"><div className="input-group has-validation">
                                                    <span className="input-group-text">Surname</span>
                                                    <input type="text" className={this.state.surnameError !== "" ? "form-control is-invalid" : "form-control"} value={this.state.surname} onChange={e => { this.setPatientValue('surname', e.target.value); }} />
                                                    <div className="invalid-feedback">{this.state.surnameError}</div>
                                                </div></div>
                                                </div>
                                                <div className="row">
                                                <div className="col"><div className="input-group">
                                                    <span className="input-group-text">National ID</span>
                                                    <input type="text" className="form-control" value={this.state.idNumber} onChange={e => { this.setPatientValue('idNumber', e.target.value); }} />
                                                </div></div>
                                            </div>
                                                <div className="row">
                                                <div className="col"><div className="input-group">
                                                    <span className="input-group-text">Passport</span>
                                                    <input type="text" className="form-control" value={this.state.passport} onChange={e => { this.setPatientValue('passport', e.target.value); }} />
                                                </div></div>
                                            </div>
                                            <div className="row">
                                                <div className="col"><div className="input-group has-validation">
                                                    <span className="input-group-text">Gender</span>
                                                    <select className={this.state.genderError !== "" ? "form-control is-invalid" : "form-control"} value={this.state.gender} onChange={e => { this.setPatientValue('gender', e.target.value); }} >
                                                        <option value="">Choose Gender</option>
                                                        {this.state.genders.map((gender, index) => (
                                                            <option key={index} value={gender} >{gender}</option>
                                                        ))}
                                                    </select>
                                                    <div className="invalid-feedback">{this.state.genderError}</div>
                                                </div></div>
                                                </div>
                                                <div className="row">
                                                <div className="col"><div className="input-group has-validation">
                                                    <span className="input-group-text">Date of Birth (yyyy-mm-dd)</span>
                                                    <DatePicker className={this.state.dateOfBirthError !== "" ? "form-control is-invalid" : "form-control"} selected={new Date(this.state.dateOfBirth)} maxDate={new Date()} onChange={d => { this.setPatientValue('dateOfBirth',d); }} dateFormat="yyyy-MM-dd" />
                                                    <input type="hidden" className={this.state.dateOfBirthError !== "" ? "form-control is-invalid" : "form-control"} /><div className="invalid-feedback">{this.state.dateOfBirthError}</div>
                                                </div></div>
                                                </div>
                                                <div className="row">
                                                <div className="col"><div className="input-group has-validation">
                                                    <span className="input-group-text">Employer</span>
                                                    <input type="text" className={this.state.employerError !== "" ? "form-control is-invalid" : "form-control"} value={this.state.employer} onChange={e => { this.setPatientValue('employer', e.target.value); }} />
                                                    <div className="invalid-feedback">{this.state.employerError}</div>
                                                </div></div>
                                            </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="panelsStayOpen-headingTwo">
                                            <button className={(this.state.contact ? '' : 'collapsed') + ' accordion-button'} onClick={()=> this.toggleTabs('contact')}> Contact Details </button>
                                        </h2>
                                        <div className={(this.state.contact ? 'show' : '') + ' accordion-collapse collapse'} >
                                            <div class="accordion-body">
                                            <div className="row">
                                                <div className="col"><div className="input-group has-validation">
                                                    <span className="input-group-text">Street / Flat / Building No.</span>
                                                    <input type="text" className={this.state.address1Error !== "" ? "form-control is-invalid" : "form-control"} value={this.state.address1} onChange={e => { this.setPatientValue('address1', e.target.value); }} />
                                                    <div className="invalid-feedback">{this.state.address1Error}</div>
                                                </div></div>
                                                </div>
                                                <div className="row">
                                                <div className="col"><div className="input-group has-validation">
                                                    <span className="input-group-text">Surburb</span>
                                                    <input type="text" className={this.state.address2Error !== "" ? "form-control is-invalid" : "form-control"} value={this.state.address2} onChange={e => { this.setPatientValue('address2', e.target.value); }} />
                                                    <div className="invalid-feedback">{this.state.address2Error}</div>
                                                </div></div>
                                                </div>
                                                <div className="row">
                                                <div className="col"><div className="input-group has-validation">
                                                    <span className="input-group-text">Town</span>
                                                    <input type="text" autoComplete="off" className={this.state.townError !== "" ? "form-control is-invalid" : "form-control"} value={this.state.town} onChange={e => { this.setPatientValue('town', e.target.value); }} list="townList" />
                                                    <datalist id="townList">
                                                        {this.state.towns.map((town, index) => (
                                                            <option key={index} value={town} >{town}</option>
                                                        ))}
                                                    </datalist>
                                                    <div className="invalid-feedback">{this.state.townError}</div>
                                                </div></div>
                                            </div>
                                            <div className="row">
                                                <div className="col"><div className="input-group has-validation">
                                                    <span className="input-group-text">Email</span>
                                                    <input type="email" className="form-control" value={this.state.email} onChange={e => { this.setPatientValue('email', e.target.value); }} />
                                                </div></div>
                                                </div>
                                                <div className="row">
                                                <div className="col"><div className="input-group has-validation">
                                                    <span className="input-group-text">Landline No.</span>
                                                    <input type="text" className="form-control" value={this.state.landline} onChange={e => { this.setPatientValue('landline', e.target.value); }} />
                                                </div></div>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="panelsStayOpen-headingThree">
                                            <button className={(this.state.nextOfKin ? '' : 'collapsed') + ' accordion-button'} onClick={()=> this.toggleTabs('nextOfKin')}>Next Of Kin Details</button>
                                        </h2>
                                        <div className={(this.state.nextOfKin ? 'show' : '') + ' accordion-collapse collapse'}>
                                            <div class="accordion-body">
                                            <div className="row">
                                                <div className="col"><div className="input-group has-validation">
                                                    <span className="input-group-text">Next Of Kin Name</span>
                                                    <input type="text" className={this.state.nextOfKinNameError !== "" ? "form-control is-invalid" : "form-control"} value={this.state.nextOfKinName} onChange={e => { this.setPatientValue('nextOfKinName', e.target.value); }} />
                                                    <div className="invalid-feedback">{this.state.nextOfKinNameError}</div> </div></div>
                                                </div>
                                                <div className="row">
                                                <div className="col"><div className="input-group has-validation">
                                                    <span className="input-group-text">Relationship to Patient</span>
                                                    <select className={this.state.nextOfKinRelationshipIdError !== "" ? "form-control is-invalid" : "form-control"} value={this.state.nextOfKinRelationshipId} onChange={e => { this.setPatientValue('nextOfKinRelationshipId', e.target.value); }}>
                                                    <option value="">Choose</option>
                                                        {this.state.relationships.map((relationship, index) => (
                                                            <option key={index} value={relationship.id} >{relationship.name}</option>
                                                        ))}
                                                    </select>
                                                    <div className="invalid-feedback">{this.state.nextOfKinRelationshipIdError}</div></div></div>
                                                </div>
                                                <div className="row">
                                                <div className="col"><div className="input-group has-validation">
                                                    <span className="input-group-text">Next of Kin Mobile</span>
                                                    <input type="text" className={this.state.nextOfKinMobileError !== "" ? "form-control is-invalid" : "form-control"} value={this.state.nextOfKinMobile} onChange={e => { this.setPatientValue('nextOfKinMobile', e.target.value); }} />
                                                    <div className="invalid-feedback">{this.state.nextOfKinMobileError}</div></div></div>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="panelsStayOpen-headingThree">
                                            <button className={(this.state.insurance ? '' : 'collapsed') + ' accordion-button'} onClick={()=> this.toggleTabs('insurance')}>Insurance Details</button>
                                        </h2>
                                        <div className={(this.state.insurance ? 'show' : '') + ' accordion-collapse collapse'}>
                                            <div class="accordion-body">
                                            <ul class="list-group">
                                            {this.state.patientInsurances.map((item, index) => (
                                            <li key={index} class="list-group-item">
                                                <div className="row">
                                                    <div className="col"><div className="input-group has-validation"><span className="input-group-text">Insurance:</span> <select className={item.insuranceIdError !== "" ? "form-control is-invalid" : "form-control"} value={item.insuranceId} onChange={e => { this.setPatientInsuranceValue(index, 'insuranceId', e.target.value); }} >
                                                                <option value="" >Choose Insurance</option>
                                                                {this.state.insurances.map((insurances, index) => (
                                                                    <option key={index} value={insurances.id} >{insurances.name}</option>
                                                                ))}
                                                            </select>
                                                                <div className="invalid-feedback">{item.insuranceIdError}</div></div></div>
                                                                </div>
                                                                <div className="row">
                                                    <div className="col"><div className="input-group has-validation"><span className="input-group-text">Member #:</span> <input type="text" className={item.memberNumberError !== "" ? "form-control is-invalid" : "form-control"} value={item.memberNumber} onChange={e => { this.setPatientInsuranceValue(index, 'memberNumber', e.target.value); }}  />
                                                                <div className="invalid-feedback">{item.memberNumberError}</div></div></div>
                                                </div>
                                                <div className="row">
                                                    <div className="col"><div className="input-group has-validation"><span className="input-group-text">Member Name:</span> <input type="text" className={item.memberNameError !== "" ? "form-control is-invalid" : "form-control"} value={item.memberName} onChange={e => { this.setPatientInsuranceValue(index, 'memberName', e.target.value); }}  />
                                                                <div className="invalid-feedback">{item.memberNameError}</div></div></div>
                                                                </div>
                                                                <div className="row">
                                                    <div className="col"><div className="input-group has-validation"><span className="input-group-text">Suffix:</span><select className={item.suffixIdError !== "" ? "form-control is-invalid" : "form-control"} value={item.suffixId} onChange={e => { this.setPatientInsuranceValue(index, 'suffixId', e.target.value); }} >
                                                                {this.state.suffixs.map((suffix, index) => (
                                                                    <option key={index} value={suffix.id} >{suffix.name}</option>
                                                                ))}
                                                            </select><div className="invalid-feedback">{item.suffixIdError}</div></div></div>
                                                </div>
                                                <div className="row">
                                                    <div className="col"><div className="input-group has-validation"><span className="input-group-text">Relationship:</span> <select className={item.relationshipIdError !== "" ? "form-control is-invalid" : "form-control"} value={item.relationshipId} onChange={e => { this.setPatientInsuranceValue(index, 'relationshipId', e.target.value); }} >
                                                            <option value="" >Choose Relationship</option>
                                                                {this.state.relationships.map((relationship, index) => (
                                                                    <option key={index} value={relationship.id} >{relationship.name}</option>
                                                                ))}
                                                            </select><div className="invalid-feedback">{item.relationshipIdError}</div></div></div>
                                                                </div>
                                                                <div className="row">
                                                    <div className="col"> {item.id === 0 ? (<td><input type="button" className="btn btn-danger" onClick={() => this.removePatientInsuranceEntry(index)} value="Remove" /></td>) : (<td></td>)}</div>
                                                </div></li>))}
                                                </ul>  <button onClick={() => this.addInsuranceTable()} className="btn btn-success" ><i className="bi bi-plus-square"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                             <div className="row">
                                <div className="col-12">
                                <div className="d-grid gap-2">{this.state.showSubmit === true &&(<button  onClick={()=> this.save()} className="btn btn-success" >Save</button>)}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {loaderDiv}

                </div>
            </div>
        )

    }



}