import React, { Component } from 'react'
import axios from 'axios'
import Top from './top';
export default class Mydetails extends Component {
    state = { error: '', msg: '', loaderDiv: false, mobile: localStorage.getItem('mobile') ? localStorage.getItem('mobile') : '', general: true, contact: false, nextOfKin: false, insurance: false
    , firstname: '', idNumber: '', middlename: '', surname: '', salutation: '', passport: '', gender: '', dateOfBirth: '', employer: '', address1: '', address2: '', town: '', email: '', landline: '', nextOfKinName: '',nextOfKinRelationship: '', nextOfKinMobile:'', patientInsurances: [] }

    componentDidMount() {
        this.setLoader(true)
        axios.get(localStorage.getItem('host') + '/auth/useraction/' + sessionStorage.getItem('userId'), {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setLoader(false)
                    this.setState({ firstname: res.data.firstname, idNumber: res.data.idNumber, middlename: res.data.middlename, surname: res.data.surname, salutation: res.data.salutation, passport:  res.data.passport, gender:  res.data.gender, dateOfBirth:  res.data.dateOfBirth, employer:  res.data.employer, address1:  res.data.address1, address2:  res.data.address2, town:  res.data.town, email:  res.data.email, landline:  res.data.landline, nextOfKinName:  res.data.nextOfKinName,nextOfKinRelationship:  res.data.nextOfKinRelationship, nextOfKinMobile: res.data.nextOfKinMobile });
                 }, err => {
                this.setState({ error: err + "" })
                this.setLoader(false)
            }
        )
    }

    doValidate() {
        setTimeout(() => {
            this.validate();
        }, 1000);
    }

    validate() {

        var error = false;
        if (this.state.mobile === null) {
            this.setState({ mobileError: "Mobile Number cannot be empty" })
            error = true
        }
        else if (this.state.mobile === "") {
            this.setState({ mobileError: "Mobile Number cannot be empty" })
            error = true
        }
        else if (!(/^[7]\d{8}$/.test(this.state.mobile))) {
            this.setState({ mobileError: "Please numbers only should not start with zero like 772747211" })
            error = true
        }
        else {
            this.setState({ mobileError: "" })
        }
        if (this.state.otpcode === null) {
            this.setState({ otpcodeError: "OTP code cannot be empty" })
            error = true
        }
        else if (this.state.otpcode === "") {
            this.setState({ otpcodeError: "OTP code cannot be empty" })
            error = true
        }
        else if (!(/^(\d{6})$/.test(this.state.otpcode))) {
            this.setState({ otpcodeError: "OTP code should be 6 digits only" })
            error = true
        }
        else {
            this.setState({ otpcodeError: "" })
        }
        if (this.state.pincode === null) {
            this.setState({ pincodeError: "Pincode cannot be empty" })
            error = true
        }
        else if (this.state.pincode === "") {
            this.setState({ pincodeError: "Pincode cannot be empty" })
            error = true
        }
        else if (!(/^(\d{4})$/.test(this.state.pincode))) {
            this.setState({ pincodeError: "Pincode should be 4 digits only" })
            error = true
        }
        else {
            this.setState({ pincodeError: "" })
        }

        if (this.state.pincode1 === null) {
            this.setState({ pincode1Error: "Pincode cannot be empty" })
            error = true
        }
        else if (this.state.pincode1 === "") {
            this.setState({ pincode1Error: "Pincode cannot be empty" })
            error = true
        }
        else if (!(/^(\d{4})$/.test(this.state.pincode1))) {
            this.setState({ pincode1Error: "Pincode should be 4 digits only" })
            error = true
        }
        else if (this.state.pincode1 !== this.state.pincode) {
            this.setState({ pincode1Error: "Pincodes do not match", pincodeError: "Pincodes do not match" })
        }
        else {
            this.setState({ pincode1Error: "" })
        }
        if (error === true) {
            this.setState({ error: "One or more of the mandatory field(s) is not filled in", showSubmit: false })
        }
        else {
            this.setState({ error: "", showSubmit: true })
        }
    }
    reset() {
        this.setState({ error: '' });
        this.setLoader(true)
        axios.get(localStorage.getItem('host') + '/login/reset/' + this.state.mobile + '/' + this.state.otpcode + '/' + this.state.pincode).then(
            res => {
                this.setLoader(false)
                if (res.data.includes('Error')) {
                    this.setState({ error: res.data });
                }
                else {
                    localStorage.setItem('msg', res.data)
                    window.location = "/login"
                }
            }, err => {
                console.log(err + "")
                this.setState({ error: err + "" })
                this.setLoader(false)
            }
        )
    }
    getOTP() {
        this.setState({ error: '' });
        this.setLoader(true)
        axios.get(localStorage.getItem('host') + '/login/resendotp/' + this.state.mobile).then(
            res => {
                this.setLoader(false)
                if (res.data.includes('Error')) {
                    this.setState({ error: res.data });
                }
                else {

                    this.setState({ msg: "Successfully sent OTP code to your phone" })

                }
            }, err => {
                this.setState({ error: err + "" })
                this.setLoader(false)
            }
        )
    }
    setLoader(loader) {
        this.setState({ loader: loader })
    }
    toggleTabs(name) {
        switch (name) {
            case "contact":
                this.setState({ general: false, contact: true, nextOfKin: false, insurance: false })
                break
            case "nextOfKin":
                this.setState({ general: false, contact: false, nextOfKin: true, insurance:false })
                break
            case "insurance":
                this.setState({ general: false, contact: false, nextOfKin: false , insurance: true})
                break
            default:
                this.setState({ general: true, contact: false, nextOfKin: false, insurance: false })
                break

        }
    }
    render() {
        let divMsg = ''
        if (this.state.error) {
            divMsg = (<div className='alert alert-danger'>{this.state.error}</div>)
        }
        if (this.state.msg) {
            divMsg = (<div className='alert alert-success'>{this.state.msg}</div>)
        }
        let loaderDiv = ''
        if (this.state.loader) {
            loaderDiv = (<div id="loader" className="loader-overlay"><div align="center" className="loader"></div></div>)
        }
        return (
            <div className='d-flex' id='wrapper'>
                <div id='page-content-wrapper'>
                    <Top />
                    <br />
                    <div className="container-fluid">
                    <div className="user-form">
                    <div  id="form">
                            <nav style={{'--bs-breadcrumb-divider': '>'}} aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><a href="/home">Home</a></li>
                                <li class="breadcrumb-item"><i class="bi bi-chevron-right"></i></li>
                                <li class="breadcrumb-item active" aria-current="page">My Details</li>
                            </ol>
                            </nav>
                                {divMsg}

                                <div class="accordion" id="accordionPanelsStayOpenExample">
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="panelsStayOpen-headingOne">
                                            <button className={(this.state.general ? '' : 'collapsed') + ' accordion-button'} onClick={()=> this.toggleTabs('general')}>General Details</button>
                                        </h2>
                                        <div className={(this.state.general ? 'show' : '') + ' accordion-collapse collapse'}>
                                            <div class="accordion-body">
                                                <div className="row">
                                                    <div className="col"><span className="fw-bold">Firstname:</span> {this.state.firstname}</div>
                                                </div>
                                                <div className="row">
                                                    <div className="col"><span className="fw-bold">Surname:</span> {this.state.surname}</div>
                                                </div>
                                                <div className="row">
                                                    <div className="col"><span className="fw-bold">Salutation:</span> {this.state.salutation}</div>
                                                </div>
                                                <div className="row">
                                                    <div className="col"><span className="fw-bold">Middlename:</span> {this.state.middlename}</div>
                                                </div>
                                                <div className="row">
                                                    <div className="col"><span className="fw-bold">Gender:</span> {this.state.gender}</div>
                                                </div>
                                                <div className="row">
                                                    <div className="col"><span className="fw-bold">DoB:</span> {this.state.dateOfBirth}</div>
                                                </div>
                                                <div className="row">
                                                    <div className="col"><span className="fw-bold">National ID:</span> {this.state.idNumber}</div>
                                                </div>
                                                <div className="row">
                                                    <div className="col"><span className="fw-bold">Passport:</span> {this.state.passport}</div>
                                                </div>
                                                <div className="row">
                                                    <div className="col"><span className="fw-bold" >Employer:</span> {this.state.employer}</div>
                                                    <div className="col"></div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="panelsStayOpen-headingTwo">
                                            <button className={(this.state.contact ? '' : 'collapsed') + ' accordion-button'} onClick={()=> this.toggleTabs('contact')}> Contact Details </button>
                                        </h2>
                                        <div className={(this.state.contact ? 'show' : '') + ' accordion-collapse collapse'} >
                                            <div class="accordion-body">
                                                <div className="row">
                                                    <div className="col"><span className="fw-bold">Street / Flat / Building Number:</span> {this.state.address1}</div>
                                                </div>
                                                <div className="row">
                                                    <div className="col"><span className="fw-bold">Surburb:</span> {this.state.address2}</div>
                                                </div>
                                                <div className="row">
                                                    <div className="col"><span className="fw-bold">Town:</span> {this.state.town}</div>
                                                </div>
                                                <div className="row">
                                                    <div className="col"><span className="fw-bold">Email:</span> {this.state.email}</div>
                                                </div>
                                                <div className="row">
                                                    <div className="col"><span className="fw-bold">Home / Work:</span> {this.state.landline}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="panelsStayOpen-headingThree">
                                            <button className={(this.state.nextOfKin ? '' : 'collapsed') + ' accordion-button'} onClick={()=> this.toggleTabs('nextOfKin')}>Next Of Kin Details</button>
                                        </h2>
                                        <div className={(this.state.nextOfKin ? 'show' : '') + ' accordion-collapse collapse'}>
                                            <div class="accordion-body">
                                                <div className="row">
                                                    <div className="col"><span className="fw-bold">Name:</span> {this.state.nextOfKinName}</div>
                                                </div>
                                                <div className="row">
                                                    <div className="col"><span className="fw-bold">Relationship:</span> {this.state.nextOfKinRelationship}</div>
                                                </div>
                                                <div className="row">
                                                    <div className="col"><span className="fw-bold">Mobile:</span> {this.state.nextOfKinMobile}</div>
                                                    <div className="col"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="panelsStayOpen-headingThree">
                                            <button className={(this.state.insurance ? '' : 'collapsed') + ' accordion-button'} onClick={()=> this.toggleTabs('insurance')}>Insurance Details</button>
                                        </h2>
                                        <div className={(this.state.insurance ? 'show' : '') + ' accordion-collapse collapse'}>
                                            <div class="accordion-body">
                                            <ul class="list-group">
                                            {this.state.patientInsurances.map((item, index) => (
                                            <li key={index} class="list-group-item">
                                                <div className="row">
                                                    <div className="col"><span className="fw-bold">Insurance Name:</span> {item.insuranceName}</div>
                                                </div>
                                                <div className="row">
                                                    <div className="col"><span className="fw-bold">Member Number:</span> {item.memberNumber}</div>
                                                </div>
                                                <div className="row">
                                                    <div className="col"><span className="fw-bold">Member Name:</span> {item.memberName}</div>
                                                </div>
                                                <div className="row">
                                                    <div className="col"><span className="fw-bold">Suffix:</span> {item.suffixName}</div>
                                                </div>
                                                <div className="row">
                                                    <div className="col"><span className="fw-bold">Relationship:</span> {item.relationshipName}</div>
                                                    <div className="col"></div>
                                                </div></li>))}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                             <div className="row">
                                <div className="col-12">
                                <div className="d-grid gap-2"><a  href="/update"  className="btn btn-success" >Update My Details</a></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {loaderDiv}

                </div>
            </div>
        )

    }



}