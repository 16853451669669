import React, { Component } from 'react'
import axios from 'axios'
import Top from '../top';
import ZoomMtgEmbedded from "@zoomus/websdk/embedded"
const KJUR = require('jsrsasign')
export default class TelemedicineCall extends Component {
    state={error: '', msg: '', loaderDiv: false, list:[], companyId: '', companys: []}

    componentDidMount() {
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let joinId = Number(params.get('joinId'));
        let password = params.get('password')
        const client = ZoomMtgEmbedded.createClient()
        let meetingSDKElement = document.getElementById('meetingSDKElement')
        client.init({ zoomAppRoot: meetingSDKElement, language: 'en-US' ,debug: true})
        console.log(JSON.stringify({
            sdkKey: '58YYfAB6HCeqogWPlf6WcloFfW89dyl6AxpC',
            signature: this.generateSignature('58YYfAB6HCeqogWPlf6WcloFfW89dyl6AxpC', 'iIfTZPtGmGSGff2xh7heKsmAuHzwVKGosKrO', joinId, 0),
            meetingNumber: Number(joinId),
            password: password,
            userName: "Mary"//sessionStorage.getItem('fullname')
         }))
        client.join({
            sdkKey: '58YYfAB6HCeqogWPlf6WcloFfW89dyl6AxpC',
            signature: this.generateSignature('58YYfAB6HCeqogWPlf6WcloFfW89dyl6AxpC', 'iIfTZPtGmGSGff2xh7heKsmAuHzwVKGosKrO',  Number(joinId), 0),
            meetingNumber: Number(joinId),
            password: password,
            userName: "Mary"//sessionStorage.getItem('fullname')
         })
      }
    openPrescription(visitId, companyId)
       {
        window.location.href = "/viewprescription?id="+visitId+'&companyId='+companyId

       }
       setLoader(loader) {
           this.setState({ loader: loader })
       }
    createMeeting()
       {
         axios.get(localStorage.getItem('host') + '/patient/getprescriptionvisitlist/'+sessionStorage.getItem('patientId'), {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setLoader(false)
                window.location.href = "/calltelemedicine?joinId="+res.data.joinId+'&password='+res.data.password+'&joinUrl='+res.data.joinUrl
            }, err => {
                this.setState({ error: err + "" })
                this.setLoader(false)
            }
        )

       }
       generateSignature(sdkKey, sdkSecret, meetingNumber, role) {

        const iat = Math.round((new Date().getTime() - 30000) / 1000)
        const exp = iat + 60 * 60 * 2
        const oHeader = { alg: 'HS256', typ: 'JWT' }
      
        const oPayload = {
          sdkKey: sdkKey,
          mn: meetingNumber,
          role: role,
          iat: iat,
          exp: exp,
          appKey: sdkKey,
          tokenExp: iat + 60 * 60 * 2
        }
        console.log(JSON.stringify(oPayload))
        const sHeader = JSON.stringify(oHeader)
        const sPayload = JSON.stringify(oPayload)
        const sdkJWT = KJUR.jws.JWS.sign('HS256', sHeader, sPayload, sdkSecret)
        return sdkJWT
      }
    render() {
        let divMsg = ''
        if (this.state.error) {
            divMsg = (<div className='alert alert-danger'>{this.state.error}</div>)
        }
        if (this.state.msg) {
            divMsg = (<div className='alert alert-success'>{this.state.msg}</div>)
        }
        let loaderDiv = ''
        if(this.state.loader)
           {
            loaderDiv = (  <div id="loader" className="loader-overlay"><div align="center" className="loader"></div></div>)
           }
        return ( <div className='d-flex' id='wrapper'>
        <div id='page-content-wrapper'>
                <Top />
                <br />
            <div className="container-fluid">
                <div className="user-form">
                <div  id="form">
                <nav style={{'--bs-breadcrumb-divider': '>'}} aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><a href="/home">Home</a></li>
                        <li class="breadcrumb-item"><i class="bi bi-chevron-right"></i></li>
                        <li class="breadcrumb-item active" aria-current="page">Telemedicine</li>
                            </ol>
                            </nav>
                    {divMsg}
                    <div id="meetingSDKElement">
</div>
                    </div>
                        </div>
                       </div>
                        {loaderDiv}

                </div>
            </div>
        )

    }



}