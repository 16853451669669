import React, { Component } from 'react'
import axios from 'axios'
import Banner from './banner';
export default class Login extends Component {
state={error: '', msg: '', loaderDiv: false, mobile: localStorage.getItem('mobile')?localStorage.getItem('mobile'):'', mobileError: '', pincode: '', pincodeError: '', ipAddress: '', country: '', isp: '', showSubmit: false}

componentDidMount() {
    var msg = localStorage.getItem('msg') && localStorage.getItem('msg') !== ""?localStorage.getItem('msg'):""
    if(msg !== null && msg !== "")
        {
            this.setState({msg: msg})
            localStorage.setItem('msg', '')
        }
    axios.get('https://api.ipdata.co?api-key=212ad9acd89c4d7e42b8b70db2317c75e051aa7f16c81fed265ef9bd').then(res => {
      this.setState({ ipAddress: res.data.ip, country: res.data.country_name, isp: res.data.asn.name });
      console.log(" ............." + res.data.ip + "...." + res.data.country_name + "" + res.data.asn.name + "")
    }, err => {
      console.log(".....")
    })

  }

doValidate() {
    setTimeout(() => {
        this.validate();
    }, 1000);
}

validate() {
       
    var error = false;
    if (this.state.mobile === null) {
        this.setState({ mobileError: "Mobile Number cannot be empty" })
        error = true
    }
    else if (this.state.mobile === "") {
        this.setState({ mobileError: "Mobile Number cannot be empty" })
        error = true
    }
    else if(!(/^[7]\d{8}$/.test(this.state.mobile)))
        {
        this.setState({ mobileError: "Please numbers only should not start with zero like 772747211"})
        error = true
        }
    else {
        this.setState({ mobileError: "" })
    }

    if (this.state.pincode === null) {
        this.setState({ pincodeError: "Pincode cannot be empty" })
        error = true
    }
    else if (this.state.pincode === "") {
        this.setState({ pincodeError: "Pincode cannot be empty" })
        error = true
    }
    else if(!(/^(\d{4})$/.test(this.state.pincode)))
        {
        this.setState({ pincodeError: "Pincode should be 4 digits only"})
        error = true
        }
    else {
        this.setState({ pincodeError: "" })
    }
    if(error === true)
           {
            this.setState({ error: "One or more of the mandatory field(s) is not filled in", showSubmit: false}) 
           }
        else
           {
            this.setState({error: "", showSubmit: true}) 
           }   
}
login() {
    this.setState({ error: '' });
    this.setLoader(true)
    var data = {mobile: this.state.mobile, pincode: this.state.pincode,
        ipAddress: this.state.ipAddress,
        country: this.state.country,
        isp: this.state.isp}
    axios.post(localStorage.getItem('host') + '/login/authenticate', data).then(
        res => {
            this.setLoader(false)
            if (res.data.error) {
                this.setState({ error: res.data.error });
              }
              else {
                 if(!localStorage.getItem('mobile') || localStorage.getItem('mobile') === null)
                    {
                        localStorage.setItem('mobile', this.state.mobile)
                    }
                  sessionStorage.setItem('token', res.data.token);
                  sessionStorage.setItem('userId', res.data.userId);
                  sessionStorage.setItem('fullname', res.data.fullname);
                  sessionStorage.setItem('mobile', res.data.mobile);
                  sessionStorage.setItem('patientId', res.data.patientId);
                  sessionStorage.setItem('refId', res.data.refId);
                  window.location = '/home'
               
              }
        }, err => { console.log(err+"")
             this.setState({error: err+""})
            this.setLoader(false)
        }
    )
}
setLoader(loader)
{
 this.setState({loader: loader})
}
forgot()
   {
    window.location = "/forgot"
   }
    render() {
        let divMsg = ''
        if (this.state.error) {
            divMsg = (<div className='alert alert-danger'>{this.state.error}</div>)
        }
        if (this.state.msg) {
            divMsg = (<div className='alert alert-success'>{this.state.msg}</div>)
        }
        let loaderDiv = ''
        if(this.state.loader)
           {
            loaderDiv = (  <div id="loader" className="loader-overlay"><div align="center" className="loader"></div></div>)
           }
        return (
            <div className='d-flex' id='wrapper'>
                <div id='page-content-wrapper'>
                        <Banner />
                        <br />
                    <div className="container-fluid">
                    <div className="user-form">
                    <div  id="form">
                        <h3>Login</h3>
                            {divMsg}
                             <div className="row">
                                <div className="col-12">
                                    <div className="input-group">
                                    <span className="input-group-text">Mobile Number 263</span>
                                                 <input type="text" className={this.state.mobileError !== "" ? "form-control is-invalid" : "form-control"} value={this.state.mobile} onChange={e => { this.setState({mobile: e.target.value}); this.doValidate() }} disabled={localStorage.getItem('mobile') && localStorage.getItem('mobile') !== null ? true : false} />
                                                 <div className="invalid-feedback">{this.state.mobileError}</div></div>
                                    </div>
                                </div>
                             <div className="row">
                                <div className="col-12">
                                    <div className="input-group">
                                    <span className="input-group-text">Pincode</span>
                                                 <input type="password" className={this.state.pincodeError !== "" ? "form-control is-invalid" : "form-control"} value={this.state.pincode} onChange={e => { this.setState({pincode: e.target.value}); this.doValidate() }} />
                                                 <div className="invalid-feedback">{this.state.pincodeError}</div></div>
                                    </div>
                                </div>
                             <div className="row">
                                <div className="col-12">
                                <div className="d-grid gap-2"><button  disabled={this.state.showSubmit === false} onClick={() => this.login()}  className="btn btn-primary" >Login</button></div>
                                    </div>
                                </div>
                             <div className="row">
                                <div className="col-12">
                                 <div className="d-grid gap-2"><button disabled={!localStorage.getItem('mobile') || localStorage.getItem('mobile') === null} onClick={() => this.forgot()}  className="btn btn-primary" >Forgot Pin</button></div>
                                    </div>
                                </div>
                             </div>
                        </div>
                       </div>
                        {loaderDiv}

                </div>
            </div>
        )

    }



}