import React, { Component } from 'react'
export default class Banner extends Component {
  componentDidMount()
  {
    
    document.getElementById("form").style.minHeight = localStorage.getItem("height")+"px";
  }
  state = { showLogin: true, showNav: Number(localStorage.getItem('width')) > 600 ? true : false, showDropDown: false, count: 0, notifications: [], showSubmit: false, username: '', usernameError: '', password: '', passwordError: '', error: '', ipAddress: '', country: '' }
  toggleNav() {
    this.setState({ showNav: !this.state.showNav })
  }

  render() {
    return (
      <nav className="navbar navbar-expand-lg navbar-dark bg-primary">
        <div className="container-fluid">
          <a className="navbar-brand" href="/"><i id="loginSvg" className="bi bi-heart-pulse-fill" ></i></a>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" onClick={() => { this.toggleNav() }} data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className={(this.state.showNav ? 'show' : '') + ' collapse navbar-collapse'}>
            <ul className="navbar-nav">
              <li className="nav-item">
                <a className="nav-link" href="/login">Login</a>
              </li>
            {(!localStorage.getItem('mobile') || localStorage.getItem('mobile') === null) && <li className="nav-item">
                <a className="nav-link" href="/signup">Signup</a>
              </li>}
            </ul>
          </div>
        </div>
      </nav>

    )
  }
}